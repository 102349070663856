<template>
  <v-row
    class="match-height mt-4"
  >
    <v-col
      cols="12"
    >
      <table-filter
        :show-payment-analytics-category-filter="true"
        :show-date-filter="false"
        @filter="applyFilters"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <revenue-card
        :current-revenue-name="'Today'"
        :current-revenue="todayRevenue.today_revenue"
        :current-revenue-abbreviated="todayRevenue.today_revenue_abbreviated"
        :previous-revenue-name="'Yesterday'"
        :previous-revenue="todayRevenue.yesterday_revenue"
        :previous-revenue-abbreviated="todayRevenue.yesterday_revenue_abbreviated"
        :is-revenue-loading="isTodayRevenueLoading"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <revenue-card
        :current-revenue-name="'This Week'"
        :current-revenue="thisWeekRevenue.this_week_revenue"
        :current-revenue-abbreviated="thisWeekRevenue.this_week_revenue_abbreviated"
        :previous-revenue-name="'Last Week'"
        :previous-revenue="thisWeekRevenue.last_week_revenue"
        :previous-revenue-abbreviated="thisWeekRevenue.last_week_revenue_abbreviated"
        :is-revenue-loading="isThisWeekRevenueLoading"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <revenue-card
        :current-revenue-name="'This Month'"
        :current-revenue="thisMonthRevenue.this_month_revenue"
        :current-revenue-abbreviated="thisMonthRevenue.this_month_revenue_abbreviated"
        :previous-revenue-name="'Last Month'"
        :previous-revenue="thisMonthRevenue.last_month_revenue"
        :previous-revenue-abbreviated="thisMonthRevenue.last_month_revenue_abbreviated"
        :is-revenue-loading="isThisMonthRevenueLoading"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <revenue-card
        :current-revenue-name="'This Year'"
        :current-revenue="thisYearRevenue.this_year_revenue"
        :current-revenue-abbreviated="thisYearRevenue.this_year_revenue_abbreviated"
        :previous-revenue-name="'Last Year'"
        :previous-revenue="thisYearRevenue.last_year_revenue"
        :previous-revenue-abbreviated="thisYearRevenue.last_year_revenue_abbreviated"
        :is-revenue-loading="isThisYearRevenueLoading"
      />
    </v-col>
    <v-col cols="12">
      <revenue-line-graph
        :is-revenue-loading="isRevenueBarGraphLoading"
        :revenue-bar-graph-data="revenueBarGraphData"
        @time-period-change="onSelectedTimePeriodChange"
      />
    </v-col>
    <v-col
      v-if="!stationId"
      cols="12"
    >
      <revenue-bar-graph
        :is-revenue-loading="isPerStationRevenueBarGraphLoading"
        :revenue-bar-graph-data="perStationRevenueBarGraphData"
        @time-period-change="onPerStationSelectedTimePeriodChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import RevenueCard from './components/RevenueCard.vue'
import RevenueBarGraph from './components/RevenueBarGraph.vue'
import RevenueLineGraph from './components/RevenueLineGraph.vue'
import calculateBarWidth from '@/mixins/calculateBarWidth'
import TableFilter from '@/components/partials/table-filter/index.vue'

export default {
  components: {
    RevenueCard, RevenueBarGraph, RevenueLineGraph, TableFilter,
  },
  mixins: [calculateBarWidth],
  props: {
    stationId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isTodayRevenueLoading: true,
    isThisWeekRevenueLoading: true,
    isThisMonthRevenueLoading: true,
    isThisYearRevenueLoading: true,
    isRevenueBarGraphLoading: true,
    isPerStationRevenueBarGraphLoading: true,
    todayRevenue: {
      today_revenue: 0,
      yesterday_revenue: 0,
    },
    thisWeekRevenue: {
      this_week_revenue: 0,
      last_week_revenue: 0,
    },
    thisMonthRevenue: {
      this_month_revenue: 0,
      last_month_revenue: 0,
    },
    thisYearRevenue: {
      this_year_revenue: 0,
      last_year_revenue: 0,
    },
    revenueBarGraphData: {
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      xaxis: {
        categories: [],
      },
      series: [{
        name: 'Revenue (Ksh)',
        data: [],
      }],
    },
    perStationRevenueBarGraphData: {
      chart: {
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: [],
      },
      series: [{
        name: 'Revenue (Ksh)',
        data: [],
      }],
    },
    selectedTimePeriod: 'daily',
    selectedPerStationTimePeriod: 'monthly',
    filterLabel: 'all',
    filters: {
      paymentType: 1,
    },
  }),
  mounted() {
    this.getTodayRevenue()
    this.getThisWeekRevenue()
    this.getThisMonthRevenue()
    this.getThisYearRevenue()
    this.getRevenueOfChart()
    this.getPerStationRevenueOfBarChart()
  },
  methods: {
    getTodayRevenue() {
      this.isTodayRevenueLoading = true
      axios
        .get(`analytics/payments/today-revenue?paymentCategory=${this.filters.paymentType}&stationId=${this.stationId}`)
        .then(response => {
          this.todayRevenue = response.data
          this.isTodayRevenueLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isTodayRevenueLoading = false
        })
    },
    getThisWeekRevenue() {
      this.isThisWeekRevenueLoading = true
      axios
        .get(`analytics/payments/this-week-revenue?paymentCategory=${this.filters.paymentType}&stationId=${this.stationId}`)
        .then(response => {
          this.thisWeekRevenue = response.data
          this.isThisWeekRevenueLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isThisWeekRevenueLoading = false
        })
    },
    getThisMonthRevenue() {
      this.isThisMonthRevenueLoading = true
      axios
        .get(`analytics/payments/this-month-revenue?paymentCategory=${this.filters.paymentType}&stationId=${this.stationId}`)
        .then(response => {
          this.thisMonthRevenue = response.data
          this.isThisMonthRevenueLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isThisMonthRevenueLoading = false
        })
    },
    getThisYearRevenue() {
      this.isThisYearRevenueLoading = true
      axios
        .get(`analytics/payments/this-year-revenue?paymentCategory=${this.filters.paymentType}&stationId=${this.stationId}`)
        .then(response => {
          this.thisYearRevenue = response.data
          this.isThisYearRevenueLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isThisYearRevenueLoading = false
        })
    },
    getRevenueOfChart() {
      this.isRevenueBarGraphLoading = true
      axios
        .get(`analytics/payments/time-series-revenue?timePeriod=${this.selectedTimePeriod}&paymentCategory=${this.filters.paymentType}&stationId=${this.stationId}`)
        .then(response => {
          const newData = response.data.data
          const chartLabels = []
          const chartValues = []

          newData.forEach(element => {
            chartValues.push(element.value)
            chartLabels.push(element.period)
          })
          const ref = this

          this.revenueBarGraphData = {
            ...this.revenueBarGraphData,
            ...{
              xaxis: {
                categories: chartLabels,
              },
              series: [{
                name: 'Revenue',
                data: chartValues,
              }],
              dataLabels: {
                enabled: true,
                formatter(val) {
                  return `${ref.formatCurrency(val)}`
                },
              },
              tooltip: {
                y: {
                  formatter(val) {
                    return `Ksh ${ref.formatCurrency(val)}`
                  },
                },
              },
            },
          }
          this.isRevenueBarGraphLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isRevenueBarGraphLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    getPerStationRevenueOfBarChart() {
      this.isPerStationRevenueBarGraphLoading = true
      axios
        .get(`analytics/payments/time-series-revenue-per-station?timePeriod=${this.selectedPerStationTimePeriod}&paymentCategory=${this.filters.paymentType}`)
        .then(response => {
          const newData = response.data.data
          let chartLabels = []
          let chartData = []
          const firstStation = newData[0]

          chartLabels = firstStation.data.map(element => element.period)
          chartData = newData.map(element => {
            const { name, data } = element
            const values = data.map(element => element.value)

            return {
              name,
              data: values,
            }
          })
          const ref = this

          this.perStationRevenueBarGraphData = {
            ...this.perStationRevenueBarGraphData,
            ...{
              xaxis: {
                categories: chartLabels,
              },
              series: chartData,
              tooltip: {
                y: {
                  formatter(val) {
                    return `Ksh ${ref.formatCurrency(val)}`
                  },
                },
              },
              dataLabels: {
                enabled: false,
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: `${this.calculateBarWidth(chartLabels.length)}%`,
                  endingShape: 'rounded',
                },
              },
            },
          }
          this.isPerStationRevenueBarGraphLoading = false
        })
        .catch(error => {
          this.isPerStationRevenueBarGraphLoading = false
          console.log(error)
          this.$notification.error(error.response.data.message)
        })
    },
    onSelectedTimePeriodChange(timePeriod) {
      this.selectedTimePeriod = timePeriod
      this.getRevenueOfChart()
    },
    onPerStationSelectedTimePeriodChange(timePeriod) {
      this.selectedPerStationTimePeriod = timePeriod
      this.getPerStationRevenueOfBarChart()
    },
    applyFilters(filters) {
      if (!Object.prototype.hasOwnProperty.call(filters, 'paymentType')) {
        return
      }
      this.filters = filters
      this.getTodayRevenue()
      this.getThisWeekRevenue()
      this.getThisMonthRevenue()
      this.getThisYearRevenue()
      this.getRevenueOfChart()
      this.getPerStationRevenueOfBarChart()
    },
    formatCurrency(value) {
      return this.$options.filters.formatCurrency(value)
    },
  },
}
</script>
