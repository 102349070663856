<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="revenue-title">Revenue {{ currentRevenueName }}</span>
    </v-card-title>

    <v-card-text v-show="isRevenueLoading">
      <revenue-card-shimmer-vue />
    </v-card-text>

    <v-card-text v-show="!isRevenueLoading">
      <div class="d-flex align-center">
        <h1 class="text-3xl font-weight-semibold revenue-amount">
          Ksh {{ currentRevenueAbbreviated }}
        </h1>

        <div class="d-flex align-center mb-n3">
          <v-icon
            size="40"
            :class="{'success--text': currentRevenue > previousRevenue, 'error--text': currentRevenue < previousRevenue}"
            class="revenue-icon"
          >
            {{ differenceIcon }}
          </v-icon>
          <span
            v-show="!isNaN(percentageDifference) && percentageDifference !== 0"
            class="text-base font-weight-medium ms-n2"
            :class="{'success--text': currentRevenue > previousRevenue, 'error--text': currentRevenue < previousRevenue}"
          >
            {{ percentageDifference | positive }}%
          </span>
        </div>
      </div>

      <h4 class="mt-2 font-weight-medium text--secondary">
        Compared to Ksh {{ previousRevenueAbbreviated }} {{ previousRevenueName }}
      </h4>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMenuUp, mdiMenuDown } from '@mdi/js'
import RevenueCardShimmerVue from './RevenueCardShimmer.vue'

export default {
  filters: {
    positive(value) {
      return Math.abs(value)
    },
  },
  components: {
    RevenueCardShimmerVue,
  },
  props: {
    currentRevenueName: {
      type: String,
      required: true,
    },
    currentRevenue: {
      type: Number,
      default: 0,
    },
    currentRevenueAbbreviated: {
      type: String,
      default: '0',
    },
    previousRevenueName: {
      type: String,
      required: true,
    },
    previousRevenue: {
      type: Number,
      default: 0,
    },
    previousRevenueAbbreviated: {
      type: String,
      default: '0',
    },
    isRevenueLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      icons: { mdiMenuUp, mdiMenuDown },
    }
  },
  computed: {
    differenceIcon() {
      if (this.currentRevenue > this.previousRevenue) {
        return this.icons.mdiMenuUp
      }
      if (this.currentRevenue < this.previousRevenue) {
        return this.icons.mdiMenuDown
      }

      return null
    },
    percentageDifference() {
      const difference = this.currentRevenue - this.previousRevenue
      const average = (this.currentRevenue + this.previousRevenue) / 2

      return Math.round((difference / average) * 100)
    },
  },
}
</script>

<style>
.revenue-title {
  font-size: 18px;
}

.revenue-amount {
  font-size: 28px;
  font-weight: bold;
}

.revenue-icon {
  margin-right: 8px;
}

.success--text {
  color: var(--success); /* Use Vuetify's success color */
}

.error--text {
  color: var(--error); /* Use Vuetify's error color */
}
</style>
